import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './Home.css'; 

function Home() {
  // Initial data for the table
  const tableData = useMemo(() => [
    { event: 'Guttas Fitnessmåling', type: 'KD🧠', vert: 'Nils & Gilberto', kvartal: '2025 Q1' },
    { event: 'Julebord hos Didrik', type: 'Julebord🎄', vert: 'Didrik', kvartal: '2024 Q4' },
    { event: 'Txotx', type: 'KL🥩', vert: 'Markus', kvartal: '2024 Q3' },
    { event: 'Båttur', type: 'KL🥩', vert: 'Arne', kvartal: '2023 Q2' },
    { event: 'Televerket', type: 'KL🥩', vert: 'Matias', kvartal: '2023 Q1' },
    { event: 'Kverneriet', type: 'KL🥩', vert: 'Christian E', kvartal: '2022 Q4' },
    { event: 'Köd', type: 'KL🥩', vert: 'Magnus', kvartal: '2020 Q3' },
    { event: 'The Night Pig', type: 'KL🥩', vert: 'Gustaf', kvartal: '2019 Q4' },
    { event: 'Ahan', type: 'KL🥩', vert: 'Audun', kvartal: '2019 Q2' },
    { event: 'Hot Shop', type: 'KL🥩', vert: 'Njaal', kvartal: '2019 Q1' },
    { event: 'Kontrast', type: 'KL🥩', vert: 'Petter', kvartal: '2018 Q4' },
    { event: 'Wallmanns', type: 'KL🥩', vert: 'Nikolai', kvartal: '2018 Q3' },
    { event: 'Kullt', type: 'KL🥩', vert: 'Glenn', kvartal: '2018 Q2' },
    { event: 'Golden Chimp', type: 'KL🥩', vert: 'Håvard', kvartal: '2018 Q1' },
    { event: 'Festningen ', type: 'KL🥩', vert: 'Martin', kvartal: '2017 Q4' },
    { event: 'Grisen ', type: 'KL🥩', vert: 'Gilberto', kvartal: '2017 Q3' },
    { event: 'Der Peppern Gror', type: 'KL🥩', vert: 'Adrian', kvartal: '2017 Q2' },
    { event: 'Smia Galleri', type: 'KL🥩', vert: 'Christian B', kvartal: '2017 Q1' },
    { event: 'Piemonte', type: 'Vino🍷', vert: 'Magnus', kvartal: '2022 Q4' },
    { event: 'Spania', type: 'Vino🍷', vert: 'Markus', kvartal: '2020 Q3' },
    { event: 'Piemonte', type: 'Vino🍷', vert: 'Håvard', kvartal: '2020 Q1' },
    { event: 'Bobler', type: 'Vino🍷', vert: 'Gustaf', kvartal: '2019 Q3' },
    { event: 'Rosé fra Provence', type: 'Vino🍷', vert: 'Adrian', kvartal: '2019 Q1' },
    { event: 'Njaals vinreise', type: 'Vino🍷', vert: 'Njaal', kvartal: '2018 Q4' },
    { event: 'Rødvin', type: 'Vino🍷', vert: 'Arne', kvartal: '2018 Q3' },
    { event: 'Chenin Blanc', type: 'Vino🍷', vert: 'Matias', kvartal: '2018 Q2' },
    { event: 'Zinfandel', type: 'Vino🍷', vert: 'Gilberto', kvartal: '2018 Q1' },
    { event: 'Kroatia-Pula', type: 'Blåtur🌍', vert: 'Njaal & Magnus', kvartal: '2017 Q3' },
    { event: 'Tyskland-Munchen', type: 'Blåtur🌍', vert: 'Christian & Matias', kvartal: '2018 Q4' },
    { event: 'Polen-Warszawa', type: 'Blåtur🌍', vert: 'Petter & Martin', kvartal: '2019 Q3' },
    { event: 'Norge-Veggli', type: 'Blåtur🌍', vert: 'Njaal & Martin', kvartal: '2020 Q4' },
    { event: 'Tyskland-Berlin', type: 'Blåtur🌍', vert: 'Gilberto, Magnus & Nikolai', kvartal: '2023 Q4' },
    { event: 'Norge-Lenningen', type: 'Blåtur🌍', vert: 'Christian B & Anders S', kvartal: '2021 Q4' },
    { event: 'Ciber & EU', type: 'KD🧠', vert: 'Njaal & Markus', kvartal: '2018 Q2' },
    { event: 'Fra billig til dyr vin', type: 'Vino🍷', vert: 'Gustaf', kvartal: '2024 Q2' },
    { event: 'Tine & Tom Wilhelmsen', type: 'KD🧠', vert: 'Adrian & Gilberto', kvartal: '2019 Q1' },
    { event: 'Intility', type: 'KD🧠', vert: 'Håvard', kvartal: '2020 Q1' },
    { event: 'HitecVision & Celsio', type: 'KD🧠', vert: 'Petter & Markus', kvartal: '2023 Q1' },
    { event: 'Pythonkurs', type: 'KD🧠', vert: 'Magnus', kvartal: '2021 Q1' },
    { event: 'Sognsvann', type: 'Julegrøt🎅', vert: 'Martin', kvartal: '2023 Q4' },
    { event: 'Hos Anders S', type: 'Julegrøt🎅', vert: 'Anders S', kvartal: '2022 Q4' },
    { event: 'Hos Petter', type: 'Julegrøt🎅', vert: 'Petter', kvartal: '2021 Q4' },
    { event: 'Sognsvann', type: 'Julegrøt🎅', vert: 'Martin&Petter', kvartal: '2020 Q4' },
    { event: 'Hos Martin', type: 'Julegrøt🎅', vert: 'Martin', kvartal: '2019 Q4' },
    { event: 'Hos Martin', type: 'Julegrøt🎅', vert: 'Martin', kvartal: '2018 Q4' },
    { event: 'Hos Martin', type: 'Julegrøt🎅', vert: 'Martin', kvartal: '2017 Q4' },
    { event: 'Nikolai jobb', type: 'Julebord🎄', vert: 'Nikolai', kvartal: '2023 Q4' },
    { event: 'Hos Nikolai', type: 'Julebord🎄', vert: 'Nikolai', kvartal: '2019 Q4' },
    { event: 'Hos Glenn', type: 'Julebord🎄', vert: 'Glenn', kvartal: '2018 Q4' },
    { event: 'Hos Nikolai', type: 'Julebord🎄', vert: 'Nikolai', kvartal: '2017 Q4' },
    // Add more items as needed
  ], []); // Empty dependency array means this only runs once on mount

  // State to store the current sort configuration
  const [sortConfig, setSortConfig] = useState({ key: 'kvartal', direction: 'descending' });
  // State to store the current filter criterion
  const [filter, setFilter] = useState(null);
  // Create a state in Home.js to keep track of the months passed:
  const [monthsSinceLastEvent, setMonthsSinceLastEvent] = useState(0);
  // State to store current filter selection 
  const [activeFilter, setActiveFilter] = useState('');

  // Function to request sorting by a column
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Function to apply filter based on item name
  const applyFilter = (itemName) => {
    setFilter(itemName); // Existing logic to filter your data based on itemName
    setActiveFilter(itemName); // Additional line to track which filter is active for UI styling
  };
 // Function to clear the applied filter
  const clearFilter = () => {
    setFilter(null); 
    setActiveFilter(''); // Clear the active filter
  };

  // useMemo hook to sort and filter items efficiently
  const sortedAndFilteredItems = useMemo(() => {
    let items = [...tableData]; // Copying tableData to avoid direct mutation
    // Filter items if a filter is set
    if (filter) {
      items = items.filter(item => item.type === filter);
    }
    // Sort items based on the current sort configuration
    if (sortConfig.key !== null) {
      items.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return items; // Return the sorted and filtered items
  }, [tableData, sortConfig, filter]); // Dependencies for useMemo
  

//Determine the date of the last event based on the quarter information:
const getLastEventDate = (quarterString) => {
  const [year, quarter] = quarterString.split(' ');
  const monthMapping = {
    'Q1': '02', // February
    'Q2': '05', // May
    'Q3': '08', // August
    'Q4': '11' // November
  };
  
  return new Date(`${year}-${monthMapping[quarter]}-15`); // Assuming 15th is the middle of the month
};

//Calculate the difference in months between the current date and the last event date:
const calculateMonthsSinceLastEvent = (lastEventDate) => {
  const now = new Date();
  const months = (now.getFullYear() - lastEventDate.getFullYear()) * 12;
  const pastMonths = now.getMonth() - lastEventDate.getMonth();
  return months + pastMonths;
};

  //Generalized Function to Find Last Event Date by Type
  const getLastEventDateByType = useCallback((eventType) => {
    // Filter events by type
    const eventsOfType = tableData.filter(event => event.type === eventType);
  
    // Sort to find the most recent event
    const sortedEvents = eventsOfType.sort((a, b) => {
      const dateA = getLastEventDate(a.kvartal);
      const dateB = getLastEventDate(b.kvartal);
      return dateB - dateA; // Descending order
    });
  
    return sortedEvents.length > 0 ? getLastEventDate(sortedEvents[0].kvartal) : null;
  }, [tableData]); // Dependency array for useCallback, include any external dependencies
  
//TIME CALCULATION
  //Calculate Months Passed for Each Type
  const monthsSinceLastKL = useMemo(() => {
    const lastKLDate = getLastEventDateByType('KL🥩');
    const months = lastKLDate ? calculateMonthsSinceLastEvent(lastKLDate) : '-';
    return months >= 0 ? months : 0;  // Display "0" if months are negative
  }, [getLastEventDateByType]); // Assuming tableData is your state or prop that holds event data
  
  const monthsSinceLastVino = useMemo(() => {
    const lastVinoDate = getLastEventDateByType('Vino🍷');
    const months = lastVinoDate ? calculateMonthsSinceLastEvent(lastVinoDate) : '-';
    return months >= 0 ? months : 0;  // Display "0" if months are negative
  }, [getLastEventDateByType]);
  
  const monthsSinceLastBlåtur = useMemo(() => {
    const lastBlåturDate = getLastEventDateByType('Blåtur🌍');
    const months = lastBlåturDate ? calculateMonthsSinceLastEvent(lastBlåturDate) : '-';
    return months >= 0 ? months : 0;  // Display "0" if months are negative
  }, [getLastEventDateByType]);

  const monthsSinceLastKD = useMemo(() => {
    const lastKDDate = getLastEventDateByType('KD🧠');
    const months = lastKDDate ? calculateMonthsSinceLastEvent(lastKDDate) : '-';
    return months >= 0 ? months : 0;  // Display "0" if months are negative
  }, [getLastEventDateByType]);

//Create a state in Home.js to keep track of the months passed:
useEffect(() => {
  // Sort tableData by the kvartal property in descending order to find the latest event
  const sortedData = tableData.sort((a, b) => {
    const quarterYearA = a.kvartal.split(' ');
    const quarterYearB = b.kvartal.split(' ');
    const yearComparison = quarterYearB[0].localeCompare(quarterYearA[0]);
    
    if (yearComparison !== 0) {
      return yearComparison;
    }
    
    // Compare quarters if the year is the same
    return quarterYearB[1].localeCompare(quarterYearA[1]);
  });

  if (sortedData.length > 0) {
    const lastEventQuarter = sortedData[0].kvartal; // Assume this is dynamically determined from your data
    const lastEventDate = getLastEventDate(lastEventQuarter);
    let monthsPassed = calculateMonthsSinceLastEvent(lastEventDate);

    // Ensure the number of months passed is not negative
    monthsPassed = monthsPassed >= 0 ? monthsPassed : 0;

    setMonthsSinceLastEvent(monthsPassed);
  }

}, [tableData]); // Include tableData in the dependency array

  return (
    <div>
      <div className="time-since-last-event">
        <p>⌚≈{monthsSinceLastEvent} mnd siden forrige event</p>
      </div>
      <div className="text-boxes-container">
        <div className="box-vertical">
          <button className="text-box box1" onClick={() => applyFilter('KL🥩')}
          >KL🥩nr.17: Diderik!</button>
            <div className="box-vertical-text">
            <p>≈{monthsSinceLastKL} mnd siden forrige KL🥩</p>
            </div>
        </div>
        <div className="box-vertical">
          <button className="text-box box2" onClick={() => applyFilter('Vino🍷')}
          >Vino🍷nr.11: Anders L!</button>
            <div className="box-vertical-text">
            <p>≈{monthsSinceLastVino} mnd siden forrige Vino🍷</p>
            </div>
        </div>
        <div className="box-vertical">
          <button className="text-box box3" onClick={() => applyFilter('Blåtur🌍')}
          >Blåtur🌍nr.7: Lasse/Audun!</button>
            <div className="box-vertical-text">
            <p>≈{monthsSinceLastBlåtur} mnd siden forrige Blåtur🌍</p>
            </div>
          </div>
        <div className="box-vertical">
            <button className="text-box box4" onClick={() => applyFilter('KD🧠')}
            >Kunnskapsdeling🧠nr.6: ???</button>
            <div className="box-vertical-text">
            <p>≈{monthsSinceLastKD} mnd siden forrige KD🧠</p>
            </div>
      </div>
    </div>
    <h1 className="events_headliner">Alle events:</h1>
        Filtre:
        <button onClick={() => applyFilter('KL🥩')}
        className={`filter-button ${activeFilter === 'KL🥩' ? 'active' : ''}`}
        >KL</button>
        <button onClick={() => applyFilter('Vino🍷')}
        className={`filter-button ${activeFilter === 'Vino🍷' ? 'active' : ''}`}
        >Vino</button>
        <button onClick={() => applyFilter('Blåtur🌍')}
        className={`filter-button ${activeFilter === 'Blåtur🌍' ? 'active' : ''}`}
        >Blåtur</button>
        <button onClick={() => applyFilter('KD🧠')}
        className={`filter-button ${activeFilter === 'Blåtur🌍' ? 'active' : ''}`}
        >Kunnskapsdeling</button>
        <button onClick={() => applyFilter('Julegrøt🎅')}
        className={`filter-button ${activeFilter === 'Julegrøt🎅' ? 'active' : ''}`}
        >Julegrøt</button>
        <button onClick={() => applyFilter('Julebord🎄')}
        className={`filter-button ${activeFilter === 'Julebord🎄' ? 'active' : ''}`}
        >Julebord</button>
        <button onClick={clearFilter}
        className={`filter-button ${activeFilter === '' ? 'active' : ''}`}
        >Uten filter</button>
        Klikk på kolonnenavn for å sortere
      <table>
        <thead>
          <tr>
            <th onClick={() => requestSort('event')}>
              Sted/konsept {sortConfig.key === 'event' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}  
            </th>
            <th onClick={() => requestSort('type')}>
              Event type {sortConfig.key === 'type' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
            </th>
            <th onClick={() => requestSort('vert')}>
              Vert {sortConfig.key === 'vert' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
            </th>
            <th onClick={() => requestSort('kvartal')}>
              Kvartal {sortConfig.key === 'kvartal' ? (sortConfig.direction === 'descending' ? '▼' : '▲') : ''}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredItems.map(item => (
            <tr key={item.id}>
              <td>{item.event}</td>
              <td>{item.type}</td>
              <td>{item.vert}</td>
              <td>{item.kvartal}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Home;